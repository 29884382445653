<template>
  <div id="Login">
    <ul>
      <li>
        <p class="grey9">{{ username ? '请输入用户名': '' }}</p>
        <input v-model="username" type="text" placeholder="请输入用户名">
      </li>
      <li>
        <p class="grey9">{{ password ? '请输入登录密码': '' }}</p>
        <input v-model="password" type="password" placeholder="请输入登录密码">
      </li>
    </ul>
    <div
      class="button"
      :class="{'disabled': isDisabled}"
      @click="submitLogin"
    >登录</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Login',
  components: {},
  props: [],
  data() {
    return {
      username: '',
      password: ''
    };
  },
  computed: {
    isDisabled() {
      return !this.username || !this.password;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapActions({
      'login': 'userInfo/login'
    }),
    submitLogin() {
      if (this.isDisabled) return;
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      });
      this.login({
        username: this.username,
        password: this.password
      }).then(res => {
        this.$router.push({ name: 'proList' });
      }).finally(() => {
        this.$toast.clear();
      });
    }
  }

};
</script>

<style lang="scss" scoped>
#Login{
  padding: 448px 60px 0;
  height: 100%;
  box-sizing: border-box;
  background: url("../assets/images/login_backgroud.png") no-repeat center center;
  background-size: 100% 100%;
  ul {
    li{
      height: 134px;
      padding:16px 42px 0;
      border-bottom: 1px rgba($color-primary, .3) solid;
      box-sizing: border-box;
      background: url("../assets/images/userName.png") no-repeat left 70px;
      background-size: 32px;
      &:last-of-type{
        background-image: url("../assets/images/password.png");
      }
      p{
        font-size: 24px;
        height: 32px;
        line-height: 32px;
      }
      input{
        height: 40px;
        line-height: 40px;
        padding: 20px 0 24px;
        width: 544px;
        background: transparent;
      }
    }
  }
  .button{
    width: 320px;
    height: 96px;
    line-height: 96px;
    background: linear-gradient(270deg, $color-primary-light 0%, $color-primary 100%);
    border-radius: 53px;
    font-size: 32px;
    text-align: center;
    color: #fff;
    float: right;
    margin-top: 98px;
    &.disabled{
      background: rgba($color-primary, .3);
    }
  }
}
</style>
